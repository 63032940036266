<div class="totals-container" [class.no-groupings]="couponData.CouponType !== couponType.System"
  [class.in-slide-up]="inSlideUp">
  <ng-container *ngIf="(hasSelectedFreebetVoucher$ | async) !== true">
    <div class="amount-container">
      <div class="amount-details">
        <div class="stake-amount-labels">
          <span class="label" data-testid="coupon-totals-stake-amount-label" i18n>
            Total Stake Amount
          </span>
        </div>
      </div>
      <div class="stake-amount" data-testid="coupon-totals-stake-amount">
        <div class="value" *ngIf="{
          minTotalStake: this.virtualsCouponQuery.minTotalStake$ | async ,
          maxTotalStake: this.virtualsCouponQuery.maxTotalStake$ | async
        } as values">
          <span class="currency-symbol" data-testid="coupon-totals-stake-amount-currency">{{ currencySymbol }}</span>
          <input type="number" step=".01" data-testid="coupon-totals-stake-amount-value"
            [class.error]="couponData.StakeGross < values.minTotalStake|| couponData.StakeGross > values.maxTotalStake"
            [min]="values.minTotalStake | number: '1.0-0'"
            [max]="values.maxTotalStake | number: '1.0-0'"
            [value]="couponData.StakeGross | number: '1.0-2' | removeComma"
            (input)="updateInputStakeValue($event.target.value)" appSelectAll>
          <span (click)="resetStakeValue()" data-testid="coupon-totals-stake-reset"
            class="reset-stake material-icons">{{'cancel'}}</span>
        </div>
      </div>
    </div>

    <div class="delimiters-content" data-testid="coupon-totals-quick-state">
      <div *ngFor="let amount of fixedStakes; trackBy: indexTrackBy;" class="delimiter delimiter-{{amount}}"
        (click)="addStakeValue(amount)" data-testid="coupon-totals-quick-state-value">
        {{ (virtualsCouponQuery.stakeChanged$ | async) ? '+' : ''}}{{(amount | number) }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedFreebetVoucher$ | async as fbVoucher">
    <freebets-voucher [code]="fbVoucher.code" [name]="fbVoucher.name" [expirationDate]="fbVoucher.endDate"
      [amount]="fbVoucher.reward.remainingValue" [showExpirationDate]="true"
      [productType]='freeBetProductType.VirtualsScheduled'></freebets-voucher>
  </ng-container>
  <ng-container [ngTemplateOutlet]="winningsDetails" *ngIf="(winningsInfoBubble$ | async) === false"
    data-testid="coupon-totals-winnings-details">
  </ng-container>

  <ng-container [ngTemplateOutlet]="winningsDetails" *ngIf="!winningsInfoBubble"></ng-container>
  <div class="odds-and-bonus">
    <div class="content" data-testid="coupon-totals" *ngIf="couponData.CouponType !== couponType.System">
      <div class="label" data-testid="coupon-totals-title" i18n>Total Odds:</div>
      <div class="value" data-testid="coupon-totals-value">{{ couponData.TotalOdds | odd }}</div>
    </div>
    <div class="content" *ngIf="couponData.CouponType === couponType.System" data-testid="coupon-totals-system">
      <div class="label" data-testid="coupon-totals-system-title" i18n>Max Odds:</div>
      <div class="value" data-testid="coupon-totals-system-value">{{ couponData.MaxOdd | odd }}</div>
    </div>

    <!-- System Bet Bonus Max and Min -->
    <div class="details-row totals" data-testid="coupon-system-bet-totals-bonus"
      [class.accumulator-bonus]="accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled"
      *ngIf="couponData.CouponType === couponType.System && (couponData.MinBonus + couponData.MaxBonus > 0)">
      <div class="content bonus">
        <div class="icon-and-perc">
          <div class="icon"></div>
          <span class="percentage" data-testid="coupon-system-bet-totals-bonus-min-bonus-percent"
            *ngIf="couponData.MinPercentageBonus !== 0">
            +{{ couponData.MinPercentageBonus * 100}}%
          </span>
          <span class="label" data-testid="coupon-system-bet-total-bonus-min-bonus-label" i18n>Min Acca Bonus!</span>
        </div>
        <div class="value" data-testid="coupon-system-bet-totals-bonus-min-bonus-value">
          <div class="arrow"></div>
          <div class="number">{{ couponData.MinBonus | currencyFormat }}</div>
        </div>
      </div>
      <div class="content bonus">
        <div class="icon-and-perc">
          <div class="icon"></div>
          <span class="percentage" data-testid="coupon-system-bet-totals-bonus-max-bonus-percent"
            *ngIf="couponData.MaxPercentageBonus !== 0">
            +{{ couponData.MaxPercentageBonus * 100}}%
          </span>
          <span class="label" data-testid="coupon-system-bet-totals-bonus-max-bonus-label" i18n>Max Acca Bonus!</span>
        </div>
        <div class="value" data-testid="coupon-system-bet-totals-bonus-max-bonus-value">
          <div class="arrow"></div>
          <div class="number">{{ couponData.MaxBonus | currencyFormat }}</div>
        </div>
      </div>
    </div>

    <!-- System Bet ACCA -->
    <coupon-acca-bonus-details *ngIf="( winningsInfoBubble$ | async )" [bonusAmount]="couponData.MaxBonus"
      [disableAnimations]="true" [cmsContent]="accumulatorBonusQuery.cmsContent$ | async"
      [percentage]="accumulatorBonusQuery.maxAccumulatorBonus$ | async"
      data-testid="coupon-totals-acca-bonus-system-bet"
      [show]="couponData.MaxBonus > 0 && accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled && couponData.CouponType !== couponType.System"
      class="details-row totals"></coupon-acca-bonus-details>

    <!-- Not System Bet ACCA -->
    <coupon-acca-bonus-details *ngIf="(winningsInfoBubble$ | async) === false" [bonusAmount]="couponData.MaxBonus"
      data-testid="coupon-totals-acca-bonus" [cmsContent]="accumulatorBonusQuery.cmsContent$ | async"
      [disableAnimations]="true" [percentage]="accumulatorBonusQuery.maxAccumulatorBonus$ | async"
      [show]="couponData.MaxBonus > 0 && accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled && couponData.CouponType !== couponType.System"
      [inCoupon]="true" [showInfoIcon]="true" class="details-row totals"></coupon-acca-bonus-details>
  </div>

  <div class="details-row pot-win" data-testid="coupon-totals-potential-winnings">
    <span class="material-icons" *ngIf="winningsInfoBubble$ | async" (click)="toggleWinningsDetails()"
      data-testid="coupon-totals-potential-winnings-info">{{'info'}}</span>
    <div class="content">
      <div class="label" data-testid="coupon-totals-potential-winnings-label" i18n>Net Potential Winnings</div>
      <div class="value">
        <span data-testid="coupon-totals-potential-winnings-value">{{ couponData.MaxWinNet | currencyFormat }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="winningDetailsExpanded$ | async" class="info-bubble-overlay" (click)="hideInfoBubble()"
    data-testid="coupon-totals-potential-winnings-info-overlay"
    [@fadeInOutDelay]="{ value: '', params: { delay: 30 } }"></div>

  <div class="winnings-info-bubble" *ngIf="winningDetailsExpanded$ | async"
    data-testid="coupon-totals-potential-winnings-info" [@fadeInOutDelay]="{ value: '', params: { delay: 30 } }">
    <div class="top-header">
      <span data-testid="coupon-totals-potential-winnings-info-text" i18n>Bet Info</span>
      <span class="info-bubble-close material-icons" data-testid="coupon-totals-potential-winnings-info-close"
        (click)="hideInfoBubble()">{{'close'}}</span>
    </div>
    <ng-container [ngTemplateOutlet]="winningsDetails"
      data-testid="coupon-totals-potential-winnings-info-content"></ng-container>
  </div>
</div>

<ng-template #winningsDetails>
  <div class="details-row totals" *ngIf="couponData.TurnoverTax > 0">
    <div class="content">
      <div class="label">
        <ng-container data-testid="coupon-totals-potential-winnings-info-vat"
          *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>VAT</ng-container>
        <ng-container data-testid="coupon-totals-potential-winnings-info-excise-duty"
          *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>Excise Duty</ng-container>
        <span class="perc" *ngIf="taxPercentageToShow"
          data-testid="coupon-totals-potential-winnings-info-excise-tax-percentage">
          ({{ taxPercentageToShow }})
        </span>
      </div>
      <div class="value">{{ couponData.TurnoverTax | currencyFormat }}</div>
    </div>
    <div class="content">
      <div class="label" i18n>Stake Net</div>
      <div class="value" data-testid="coupon-totals-potential-winnings-info-turnover-tax">
        {{ (couponData.StakeGross - couponData.TurnoverTax) | currencyFormat }}
      </div>
    </div>
  </div>

  <!-- System Bet -->
  <div class="details-row totals" *ngIf="couponData.CouponType === couponType.System">
    <div class="content even">
      <div class="label" data-testid="coupon-totals-potential-winnings-min-odds-label" i18n>Min. Odds</div>
      <div class="value" data-testid="coupon-totals-potential-winnings-min-odds-value">{{ couponData.MinOdd | odd }}
      </div>
    </div>
    <div class="content odd">
      <div class="label" data-testid="coupon-totals-potential-winnings-max-odds-label" i18n>Max. Odds</div>
      <div class="value" data-testid="coupon-totals-potential-winnings-max-odds-value">{{ couponData.MaxOdd | odd }}
      </div>
    </div>
  </div>

  <div class="details-row totals" *ngIf="couponData.CouponType === couponType.System">
    <div class="content even">
      <div class="label" data-testid="coupon-totals-potential-winnings-min-win-label" i18n>Min. Win</div>
      <div class="value" data-testid="coupon-totals-potential-winnings-min-win-value">{{ couponData.NetStakeMinWin |
        currencyFormat }}</div>
    </div>
    <div class="content odd">
      <div class="label" data-testid="coupon-totals-potential-winnings-max-win-label" i18n>Max. Win</div>
      <div class="value" data-testid="coupon-totals-potential-winnings-max-win-value">{{ couponData.NetStakeMaxWin |
        currencyFormat }}</div>
    </div>
  </div>

  <div class="details-row totals"
    *ngIf="couponData.CouponType === couponType.System && couponData.MaxWithholdingTax > 0">
    <div class="content even">
      <div class="label">
        <ng-container data-testid="coupon-totals-potential-winnings-min-inc-tax"
          *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>Min Inc. Tax</ng-container>
        <ng-container data-testid="coupon-totals-potential-winnings-min-wth-tax"
          *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>Min. WTH Tax</ng-container>
      </div>
      <div class="value" data-testid="coupon-totals-potential-winnings-min-witholding-tax">
        {{ couponData.MinWithholdingTax | currencyFormat }}
      </div>
    </div>
    <div class="content odd">
      <div class="label">
        <ng-container data-testid="coupon-totals-potential-winnings-max-inc-tax"
          *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>Max Inc. Tax</ng-container>
        <ng-container data-testid="coupon-totals-potential-winnings-max-wth-tax"
          *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>Max. WTH Tax</ng-container>
      </div>
      <div class="value" data-testid="coupon-totals-potential-winnings-max-witholding-tax">
        {{ couponData.MaxWithholdingTax | currencyFormat }}
      </div>
    </div>
  </div>

  <div class="details-row totals" *ngIf="(winningsInfoBubble$ | async) && couponData.CouponType !== couponType.System"
    data-testid="coupon-totals-bonus"
    [class.accumulator-bonus]="accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled">
    <div class="content even bonus">
      <div class="label">
        <span data-testid="coupon-totals-bonus-label" i18n>Accumulator Bonus</span>
        <div class="perc" *ngIf="couponData.MaxPercentageBonus !== 0" data-testid="coupon-totals-bonus-percentage">
          ({{ couponData.MaxPercentageBonus * 100 | number:'1.0-0' }}%)
        </div>
      </div>
      <div class="value" data-testid="coupon-totals-bonus-value">{{ couponData.MaxBonus | currencyFormat }}</div>
    </div>
  </div>
  <div class="details-row totals" *ngIf="(winningsInfoBubble$ | async) && couponData.CouponType !== couponType.System">
    <div class="content odd">
      <div class="label" data-testid="coupon-totals-bonus-max-win-label" i18n>Max. Win</div>
      <div class="value" data-testid="coupon-totals-bonus-max-win-value">{{ couponData.NetStakeMaxWin | currencyFormat
        }}</div>
    </div>
  </div>
  <div class="details-row totals"
    *ngIf="couponData.CouponType !== couponType.System && couponData.MaxWithholdingTax > 0">
    <div class="content even">
      <div class="label">
        <ng-container data-testid="coupon-totals-bonus-income-tax" *ngIf="applicationQuery.isBrand(['Ethiopia'])"
          i18n>Income TAX (15%)</ng-container>
        <ng-container data-testid="coupon-totals-bonus-withholding-tax" *ngIf="!applicationQuery.isBrand(['Ethiopia'])"
          i18n>Withholding Tax</ng-container>
      </div>
      <div class="value">{{ couponData.MaxWithholdingTax | currencyFormat }}</div>
    </div>
  </div>

  <div class="details-row totals"
    [class.accumulator-bonus]="accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled"
    *ngIf="couponData.CouponType === couponType.System && (couponData.MinBonus + couponData.MaxBonus > 0)">
    <div class="content even bonus">
      <div class="icon-and-perc">
        <div class="icon"></div>
        <span class="percentage" data-testid="coupon-totals-potential-winnings-min-bonus-percentage"
          *ngIf="couponData.MinPercentageBonus !== 0">
          +{{ couponData.MinPercentageBonus * 100}}%
        </span>
        <span class="label" data-testid="coupon-totals-potential-winnings-min-bonus-label" i18n>Min Acca Bonus!</span>
      </div>
      <div class="value" data-testid="coupon-totals-potential-winnings-min-bonus-value">
        <div class="arrow"></div>
        <div class="number">{{ couponData.MinBonus | currencyFormat }}</div>
      </div>
    </div>
    <div class="content odd bonus">
      <div class="icon-and-perc">
        <div class="icon"></div>
        <span class="percentage" *ngIf="couponData.MaxPercentageBonus !== 0"
          data-testid="coupon-totals-potential-winnings-max-bonus-percentage">
          +{{ couponData.MaxPercentageBonus * 100}}%
        </span>
        <span class="label" data-testid="coupon-totals-potential-winnings-max-bonus-label" i18n>Max Acca Bonus!</span>
      </div>
      <div class="value" data-testid="coupon-totals-potential-winnings-max-bonus-value">
        <div class="arrow"></div>
        <div class="number">{{ couponData.MaxBonus | currencyFormat }}</div>
      </div>
    </div>
  </div>

  <div class="details-row totals" *ngIf="couponData.CouponType === couponType.System">
    <div class="content even">
      <div class="label" data-testid="coupon-totals-potential-winnings-min-win-label" i18n>Net Potential Winnings</div>
      <div class="value" data-testid="coupon-totals-potential-winnings-min-win-value">{{ couponData.MaxWinNet |
        currencyFormat }}</div>
    </div>
  </div>
</ng-template>
