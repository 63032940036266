<div class="container" [class.tall]="showExpirationDate" data-testid="free-bets-voucher">
  <div class="voucher-beginning">
    <div class="filler"></div>
    <div class="stub"></div>
    <div class="filler"></div>
  </div>
  <div class="icon-container">
    <div class="voucher-icon" [class.not-selected]="showToggle && (isSelected$ | async) === false"></div>
  </div>
  <div class="seperator"></div>
  <div class="details" data-testid="free-bets-voucher-details">
    <div class="details-container">
      <div class="name-and-date">
        <div i18n class="voucher-name" [class.not-selected]="showToggle && (isSelected$ | async) === false" data-testid="free-bets-voucher-name">{{ name }}</div>
        <div i18n class="expiration-date" *ngIf="showExpirationDate" data-testid="free-bets-voucher-date">Expires {{ expirationDate | date: 'dd/MM/yy' }}</div>
      </div>
      <div class="amount-and-toggle">
        <div i18n class="amount" [class.not-selected]="showToggle && (isSelected$ | async) === false" data-testid="free-bets-voucher-amount">{{ amount | currencyFormat }}</div>
        <app-toggle-switch
          customClass="smaller"
          *ngIf="showToggle"
          (click)="handleClickToggle()"
          [switch]="isSelected$ | async"
          data-testid="free-bets-voucher-switch"
        ></app-toggle-switch>
      </div>
    </div>
  </div>
  <div class="voucher-end">
    <div class="filler"></div>
    <div class="stub"></div>
    <div class="filler"></div>
  </div>
</div>
