<div class="event-details">
  <div class="selection-name">
    {{ odd.SelectionName }}
  </div>
  <div class="market-event-wrapper">
    <div class="market-name">
      <span>{{ odd.MarketName }} -</span>
    </div>
    <div class="event-name">{{ odd.EventName }}</div>
  </div>
</div>

<div class="odd-details">
  <div class="odd-value">
    <div class="normal-odd-value">
      <i *ngIf="!!odd.isBoosted" class="odds-boost-badge"></i>
      <span>{{ odd.OddValue | number : '1.2-2' }}</span>
    </div>
    <div *ngIf="!!odd.isBoosted" class="unboosted-odd-value">
      {{ odd.UnboostedOddValue | number : '1.2-2' }}
    </div>
  </div>
</div>
