<ng-container *ngIf="{
    coupon: virtualsCouponQuery.couponData$ | async,
    selectedGroupingsTab: virtualsCouponQuery.groupingsTabSelected$ | async,
    authenticated: accountQuery.isAuthenticated$ | async,
    expiredOdds: expiredOdds$ | async,
    expiredWeeks: expiredWeeks$ | async,
    filteredCoupon: virtualsCouponQuery.couponDataFiltered$ | async,
    canUserBetOnVirtuals: accountQuery.isVirtualsEnabled$ | async,
    canPostCoupon: canPostCoupon$ | async,
    nextEventExpiresCountDown: nextEventExpiresCountDown$ | async,
    nextEventExpiresDate: nextEventExpiresDate$ | async,
    nextEventCategoryId: nextEventCategoryId$ | async,
    inSlideUp: virtualsQuery.showingCouponSlideUp$ | async,
    minTotalStake: virtualsCouponQuery.minTotalStake$ | async,
    maxTotalStake: virtualsCouponQuery.maxTotalStake$ | async,
    minTotalStakeErrorMessage: minTotalStakeErrorMessage$ | async,
    maxTotalStakeErrorMessage: maxTotalStakeErrorMessage$ | async
  } as vm">
  <ng-container *ngIf="vm.coupon && vm.coupon.Odds.length > 0; else emptyCoupon">
    <div class="coupon-header" *ngIf="vm.inSlideUp === false" data-testid="coupon-header">
      <div class="coupon-title">
        <span data-testid="coupon-header-title" i18n>Virtual Betslip</span>
        <span class="coupon-selections-count" data-testid="coupon-header-selections-count">{{ vm.coupon.Odds.length
          }}</span>
      </div>
      <span class="coupon-close material-icons" data-testid="coupon-close-icon" (click)="goBack()">{{ 'close' }}</span>
    </div>
    <div class="top-panel" [class.no-tabs]="vm.coupon.CouponType === couponType.Single"
      [class.in-slide-up]="vm.inSlideUp" appResizeOnScroll [minHeight]="104" [resizeEnabled]="vm.inSlideUp === false"
      [siblingHeight]="56">
      <div class="clear-all" (click)="clearCouponData()" data-testid="coupon-header-clear-selections">
        <span class="icon material-icons">{{'delete icon'}}</span>
        <span class="text" i18n>Clear All</span>
      </div>

      <div class="expiration-info" [class.expired]="vm.expiredOdds.length > 0" data-testid="expiration-info">
        <ng-container *ngIf="vm.expiredOdds.length > 0; else countDown" i18n>You have {{ vm.expiredOdds.length }}
          expired selections.
        </ng-container>
        <ng-template #countDown>
          <span data-testid="expiration-info-next-even-title" i18n>Next event will expire in: </span>
          <div class="countdown" [ngClass]="{
            'color-bg': vm.nextEventExpiresCountDown <= showColorBackgroundStartTime,
            'blinking-bg': vm.nextEventExpiresCountDown <= showFlashingBackgroundStartTime }">
            <span data-testid="expiration-info-next-even-time">{{vm.nextEventExpiresCountDown | date: 'mm:ss'}}</span>
            <ng-container *ngIf='virtualsQuery.categoryEventTiming$(vm.nextEventCategoryId) | async as countdown'>
              <!-- Adding 1second to avoid glitch when full pie container is hidden and countdown is shown -->
              <div class="countdown-pie-timer">
                <app-pie-countdown *ngIf="vm.nextEventExpiresCountDown <= (countdown.eventInterval + 1000)"
                  class="pie-countdown" [targetDate]="vm.nextEventExpiresDate.toString()"
                  [pieFullDuration]="countdown.eventInterval" [showCountdownText]="false"
                  [color]="vm.nextEventExpiresCountDown <= showColorBackgroundStartTime ? 'rgba(255, 255, 255, 0.56)' : 'rgba(0, 0, 0, 0.87)'"
                  [showBackground]="false" [pieSize]="8" [borderThickness]="2" [borderSpacing]="1"></app-pie-countdown>
              </div>
              <div class="full-pie-timer" *ngIf="vm.nextEventExpiresCountDown >= countdown.eventInterval">
                <div class="pie-border">
                  <div class="pie"></div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </div>

      <div class="groupings-tab-control" *ngIf="vm.coupon.CouponType !== couponType.Single" data-testid="groupings-tab">
        <!-- We can only determine if it's a multiple or split bet based on the number of combinations available for the last grouping -->
        <div class="groupings-tab" *ngIf="groupingTabsVisible.multiple"
          [class.selected]="vm.selectedGroupingsTab === groupingType.Multiple"
          (click)="groupingTabClicked(groupingType.Multiple)" data-testid="groupings-tab-multiple" i18n>
          Multiple
        </div>
        <!-- We can only determine if it's a multiple or split bet based on the number of combinations available for the last grouping -->
        <div class="groupings-tab" *ngIf="groupingTabsVisible.split"
          [class.selected]="vm.selectedGroupingsTab === groupingType.Split"
          (click)="groupingTabClicked(groupingType.Split)" data-testid="groupings-tab-split-bet" i18n>
          Split Bet
        </div>
        <div class="groupings-tab" *ngIf="groupingTabsVisible.singles"
          [class.selected]="vm.selectedGroupingsTab === groupingType.Singles"
          (click)="groupingTabClicked(groupingType.Singles)" data-testid="groupings-tab-singles" i18n>
          Singles
        </div>
        <div class="groupings-tab" *ngIf="groupingTabsVisible.combination && allowCombinationBets"
          [class.selected]="vm.selectedGroupingsTab === groupingType.Combination"
          (click)="groupingTabClicked(groupingType.Combination)" data-testid="groupings-tab-combination-bets" i18n>
          Combination
        </div>
      </div>
    </div>

    <div #couponWrapper class="coupon-wrapper" cdkScrollable>
      <div class="main-content" [ngClass]="{ authenticated: vm.authenticated }" data-testid="coupon">
        <app-virtuals-coupon-selections data-testid="coupon-selections" [expiredWeeks]="vm.expiredWeeks"
          [selectedGroupingTab]="vm.selectedGroupingsTab"
          (removeOddsForWeek)="removeOddsForWeek($event)"></app-virtuals-coupon-selections>

        <coupon-freebets-available [productType]='freeBetProductType.VirtualsScheduled'></coupon-freebets-available>

        <app-virtuals-coupon-groupings *ngIf="vm.coupon.CouponType !== couponType.Single" data-testid="coupon-groupings"
          [hidden]="vm.selectedGroupingsTab === groupingType.Multiple" [groups]="vm.coupon.AllGroupings">
        </app-virtuals-coupon-groupings>

        <app-virtuals-coupon-totals data-testid="coupon-totals" [couponData]="vm.filteredCoupon"
          [inSlideUp]="vm.inSlideUp">
        </app-virtuals-coupon-totals>

        <div *ngIf="vm.coupon.StakeGross < vm.minTotalStake" class="stake-alert">
          <virtuals-alert [type]="virtualsAlertType.Error" [message]="vm.minTotalStakeErrorMessage" i18n-ctaText
            ctaText="Update" (ctaClick)="updateStake(vm.minTotalStake)"></virtuals-alert>
        </div>

        <div *ngIf="vm.coupon.StakeGross > vm.maxTotalStake" class="stake-alert">
          <virtuals-alert [type]="virtualsAlertType.Error" [message]="vm.maxTotalStakeErrorMessage" i18n-ctaText
            ctaText="Update" (ctaClick)="updateStake(vm.maxTotalStake)"></virtuals-alert>
        </div>

        <div class="buttons">
          <app-button i18n *ngIf="vm.expiredWeeks.length === 0; else removeExpired" i18n-text text="Place Bet"
            data-testid="coupon-place-bet-btn" [customButtonStyle]="actionButtonStyle"
            [buttonType]="buttonType.HighlightSecondary"
            [disabled]="!vm.canUserBetOnVirtuals || !vm.canPostCoupon || vm.coupon.StakeGross < vm.minTotalStake || vm.coupon.StakeGross > vm.maxTotalStake"
            (btnClick)="postCoupon()">
          </app-button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyCoupon>
  <div class="coupon-empty-wrapper" data-testid="coupon-empty">
    <div class="coupon-header" *ngIf="(virtualsQuery.showingCouponSlideUp$ | async) === false"
      data-testid="coupon-header">
      <div class="coupon-title">
        <span data-testid="coupon-header-title" i18n>Virtual Betslip</span>
      </div>
      <span class="coupon-close material-icons" data-testid="coupon-close-icon" (click)="goBack()">{{ 'close' }}</span>
    </div>
    <div class="coupon-empty" data-testid="coupon-empty">
      <div class="coupon-empty-title" i18n>Your Betslip is currently empty</div>
      <div class="coupon-empty-content" i18n>Close Betslip and click on the odds to add your selections.</div>
    </div>
    <app-button i18n-text text="Continue Betting" (click)="continueBetting()" [customButtonStyle]="actionButtonStyle"
      [buttonType]="buttonType.Highlight" data-testid="coupon-continue-betting"></app-button>
  </div>
</ng-template>

<ng-template #betInProgress>
  <app-loading-bar [show]="true" [transparent]="true"></app-loading-bar>
  <div class="loading-text" data-testid="coupon-bet-in-progress" i18n>Your bet is being processed</div>
</ng-template>

<ng-template #removeExpired>
  <app-button i18n i18n-text text="Remove expired" [customButtonStyle]="actionButtonStyle"
    [buttonType]="buttonType.DangerTransparent" (click)="removeExpiredOdds()">
    data-testid="coupon-bet-remove-expired"
  </app-button>
</ng-template>
