import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { format, subYears } from 'date-fns';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { BonusEligibilityModel, BonusInfoModel, BonusModel, PromotionType } from 'src/app/modules/accounts/modules/auth/models/bonus.model';
import {
  AccountMenuLinkModel,
  AccountState,
  AccountStatementFilterModel,
  AccountUIState,
  BetSearchState,
  CasinoSearchState,
  UserModel,
  UserType,
  VerifyAccountModel,
} from 'src/app/shared/models/account.model';
import { UserProfilesModel } from 'src/app/shared/models/bank-profile.model';
import { USER_BANK_PROFILE_STATE_KEY } from 'src/app/shared/utils/local-storage-keys';
import { getCookie } from 'src/app/shared/utils/cookie-actions';
import { AccountStore } from './account.store';

@Injectable({ providedIn: 'root' })
export class AccountQuery extends Query<AccountState> {
  userData$ = this.select(state => state.userData);
  f2pUserData$ = this.select(state => state.f2pUserData);
  winNotificationSetting$ = this.select(state => state.winNotificationSetting);
  verifyAccountState$ = this.select(state => state.verifyAccountState);
  showUnverifiedTooltip$ = this.select(state => state.showUnverifiedTooltip);
  multipleUnconfirmedUsers$ = this.select(state => state.multipleUnconfirmedUsers);
  isAuthenticated$ = this.select(state => this.hasValidLogin());
  // Virtual games are enabled (in demo mode) for logged out users
  isVirtualsEnabled$ = this.select(state => (state.userData ? state.userData.isVirtualsEnabled : true));
  isGoldenRaceEnabled$ = this.select(state => (state.userData ? state.userData.isGoldenRaceEnabled : true));
  betSearch$ = this.select(state => state.betSearch);
  casinoSearch$ = this.select(state => state.casinoSearch);
  menuItems$ = this.select(state => state.menuItems);
  helpMenuItems$ = this.select(state => state.helpMenuItems);
  transferTypes$ = this.select(state => state.transferTypes);
  transactionStatues$ = this.select(state => state.transactionStatues);
  accountStatements$ = this.select(state => state.accountStatements);
  viewingBonus$ = this.select(state => state.viewingBonus);
  userBankProfile$ = this.select(state => state.userBankProfile);
  bonusUI$ = this.select(state => state.ui.bonus);
  loading$ = this.selectLoading();
  noStatements$ = this.select(state => state.ui.noStatements);
  error$ = this.selectError();
  refreshingBalance$ = this.select(state => state.ui.refreshingBalance);
  resetPasswordOption$ = this.select(s => s.resetPasswordOption);
  resetOptionTriggered$ = this.select(s => s.resetOptionTriggered);
  changePhoneNumberOption$ = this.select(s => s.changePhoneNumberOption);
  phoneOptionTriggered$ = this.select(s => s.phoneOptionTriggered);
  qtyAllowedProfiles$ = this.select(s => s.qtyAllowedProfiles);
  qtyEnabledProfiles$ = this.select(s => s.qtyEnabledProfiles);
  sbadIndicatorState$ = this.select(s => s.sbadIndicatorState);
  generalBanksList$ = this.select(s => s.generalBanksList);
  isShopOwner$ = this.select(s => (s.userData ? s.userData.userTypeCode === UserType.ShopOwner : false));
  isAllowedBankAccInfo$ = this.select(state => state.isAllowedBankAccInfo);
  hasResetPasswordOption =
    this.getValue().resetPasswordOption !== undefined &&
    this.getValue().resetPasswordOption !== 'none' &&
    this.getValue().resetPasswordOption.length > 0;
  hasChangePhoneNumberOption =
    this.getValue().changePhoneNumberOption !== undefined &&
    this.getValue().changePhoneNumberOption !== 'none' &&
    this.getValue().changePhoneNumberOption.length > 0;
  activeBonuses$ = this.select(state =>
    state.bonuses ? state.bonuses.filter(bonus => bonus.isActivated && !(bonus.isExpired || bonus.isCancelled || bonus.isPaused)) : []
  );
  pausedBonuses$ = this.select(state =>
    state.bonuses ? state.bonuses.filter(bonus => bonus.isActivated && !(bonus.isExpired || bonus.isCancelled) && bonus.isPaused) : []
  );
  previousBonuses$ = this.select(state =>
    state.bonuses ? state.bonuses.filter(bonus => bonus.isActivated && bonus.isExpired && !bonus.isCancelled) : []
  );
  inactiveBonuses$ = this.select(state =>
    state.bonuses ? state.bonuses.filter(bonus => !(bonus.isActivated || bonus.isExpired || bonus.isCancelled)) : []
  );
  missedBonuses$ = this.select(state =>
    state.bonuses ? state.bonuses.filter(bonus => bonus.isCancelled || (!bonus.isActivated && bonus.isExpired)) : []
  );
  firstBonus$ = this.select(state => state.bonuses && state.bonuses[0]);
  bonusInfo$ = this.select(state => state.bonusInfo);
  bonusEligibility$ = this.select(state => state.bonusEligibility);
  hasBonusInfo$ = this.select(state => state.bonusInfo !== undefined && this.getValue().bonusInfo.length > 0);
  totalFunds$ = this.select(state => {
    let result = 0;
    if (state.userData) {
      state.userData.wallets.forEach(wallet => {
        if (
          this.bonusEnabled &&
          wallet.name === 'Bonus Sports' &&
          Array.isArray(state.bonuses) &&
          state.bonuses.length > 0 &&
          state.bonuses[0]?.isExpired &&
          !state.bonuses[0]?.isBonusTransferPossible
        ) {
          return;
        }
        result += wallet.balance;
      });
    }
    return result;
  });
  transferCandidates$ = this.select(state => state.transferCandidates);
  userProfileState$ = new BehaviorSubject(this.localStorage.retrieve(USER_BANK_PROFILE_STATE_KEY));
  isUserDataComplete$ = this.select(state => this.checkIsUserDataComplete(state.userData));
  isPhoneVerified$ = this.select(state => (state.userData ? state.userData.mobile.verifyType === 'VER' : false));
  loginPageContent$ = this.select(state => state.loginPageContent);
  changePasswordPageContent$ = this.select(state => state.changePasswordPageContent);
  pendingTransactionCount$ = this.select(s => s.pendingTransactionCount);

  private readonly bonusEnabled = this.appConfigService.get('bonusEnabled');

  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly appConfigService: AppConfigService,
    protected store: AccountStore
  ) {
    super(store);
  }

  get resetPasswordOption(): string {
    return this.getValue().resetPasswordOption;
  }

  get changePhoneNumberOption(): string {
    return this.getValue().changePhoneNumberOption;
  }

  get userData(): UserModel {
    return this.getValue().userData;
  }

  get f2pUserData(): UserModel {
    return this.getValue().f2pUserData;
  }

  get dataLayerUserType(): string {
    switch (this.getValue().userData.userTypeCode) {
      case UserType.Cashier:
        return 'C';
      case UserType.Master:
        return 'MA';
      case UserType.ShopOwner:
        return 'A';
      case UserType.SubAccountPlayer:
        return 'SUB';
      case UserType.SuperAgent:
        return 'SA';
      case UserType.User:
      default:
        return 'U';
    }
  }

  get verifyAccountState(): VerifyAccountModel {
    return this.getValue().verifyAccountState;
  }

  get showUnverifiedTooltip(): boolean {
    return this.getValue().showUnverifiedTooltip;
  }

  get multipleUnconfirmedUsers(): boolean {
    return this.getValue().multipleUnconfirmedUsers;
  }

  get betSearch(): BetSearchState {
    return this.getValue().betSearch;
  }

  get casinoSearch(): CasinoSearchState {
    return this.getValue().casinoSearch;
  }

  get menuItems(): AccountMenuLinkModel[] {
    return this.getValue().menuItems;
  }

  get isAuthenticated(): boolean {
    const token = getCookie('accessToken');
    return token && token !== 'undefined';
  }

  get isAuthenticatedF2P(): boolean {
    return this.hasValidLogin();
  }

  get isVirtualsEnabled(): boolean {
    if (this.isAuthenticated) {
      return this.userData.isVirtualsEnabled;
    }
    return true; // Virtual games are enabled (in demo mode) for logged out users
  }

  get isGoldenRaceEnabled(): boolean {
    if (this.isAuthenticated) {
      return this.userData.isGoldenRaceEnabled;
    }
    return true; // Virtual games are enabled (in demo mode) for logged out users
  }

  get isJackpotBetsEnabled(): boolean {
    if (this.isAuthenticated) {
      return this.userData.isJackpotBetsEnabled;
    } else {
      const jackpotBetsConfig = this.appConfigService.get('jackpotBets');
      if (jackpotBetsConfig && !jackpotBetsConfig.userWhiteListEnabled) {
        return true;
      } else {
        return false;
      }
    }
  }

  get uiState(): AccountUIState {
    return this.getValue().ui;
  }

  get accessToken(): string {
    if (this.isAuthenticated) {
      return getCookie('accessToken');
    }
    return undefined;
  }

  get accountStatementFilter(): AccountStatementFilterModel {
    return this.getValue().accountStatementFilter;
  }

  get transferTypes(): number[] {
    return this.getValue().transferTypes.map(item => item.id);
  }

  get transactionStatues(): number[] {
    return this.getValue().transactionStatues.map(item => item.id);
  }

  get isViewingAccountStatement(): boolean {
    return this.getValue().accountStatements.length > 0;
  }

  get isViewingBonus(): boolean {
    return this.getValue().viewingBonus !== undefined;
  }

  get refreshingBalance(): boolean {
    return this.getValue().ui.refreshingBalance;
  }

  get dobUpperLimit(): string {
    return format(subYears(new Date(), 18), 'yyyy-MM-dd');
  }

  get bonusInfo(): BonusInfoModel[] {
    return this.getValue().bonusInfo;
  }

  get hasBonusInfo(): boolean {
    return this.getValue().bonusInfo !== undefined && this.getValue().bonusInfo.length > 0;
  }

  get userBankProfile(): UserProfilesModel[] {
    return this.getValue().userBankProfile;
  }

  get isUserDataComplete(): boolean {
    return this.checkIsUserDataComplete(this.getValue().userData);
  }

  get userProfileState(): any {
    return this.localStorage.retrieve(USER_BANK_PROFILE_STATE_KEY);
  }

  get isAllowedBankAccInfo(): boolean {
    return this.getValue().isAllowedBankAccInfo;
  }

  get qtyAllowedProfiles(): number {
    return this.getValue().qtyAllowedProfiles;
  }

  get qtyEnabledProfiles(): number {
    return this.getValue().qtyEnabledProfiles;
  }

  get sbadIndicatorState(): boolean {
    return this.getValue().sbadIndicatorState;
  }

  get isPhoneVerified(): boolean {
    return this.getValue().userData ? this.getValue().userData.mobile.verifyType === 'VER' : false;
  }

  get winNotificationSetting(): boolean {
    return this.getValue().winNotificationSetting;
  }

  get hasLoginPageContent(): boolean {
    return !!this.getValue().loginPageContent;
  }

  get hasChangePasswordPageContent(): boolean {
    return !!this.getValue().changePasswordPageContent;
  }

  get userStatus(): string {
    return this.getValue().userData ? this.getValue().userData.userStatus : undefined;
  }

  get bonusEligibility(): BonusEligibilityModel {
    return this.getValue().bonusEligibility;
  }

  get pendingTransactionCount(): string {
    return this.getValue().pendingTransactionCount;
  }

  transferCandidateBalance(userId: string): number {
    return this.getValue().transferCandidates !== undefined && this.getValue().transferCandidates.find(tc => tc.userId === userId).balance;
  }

  getBonus(id: number): BonusModel {
    return this.getValue().bonuses.find(bonus => bonus.id === id);
  }

  getBonusType(id: number): PromotionType {
    return this.bonusInfoByCode(this.getBonus(id).bonusCode).type;
  }

  getBonusSummary(id: number): string {
    return this.bonusInfoByCode(this.getBonus(id).bonusCode).summary;
  }

  getBonusName(id: number): string {
    return this.bonusInfoByCode(this.getBonus(id).bonusCode).name;
  }

  getBonusAdditionalInfoURL(id: number): string {
    return this.bonusInfoByCode(this.getBonus(id).bonusCode).additionalInfoURL;
  }

  bonusInfoByCode(code: number): BonusInfoModel {
    return this.getValue().bonusInfo.find(bi => bi.bonusCode === code);
  }

  private checkIsUserDataComplete(userData: UserModel): boolean {
    return (
      !!userData.name &&
      !!userData.surname &&
      !!userData.email &&
      !!userData.gender &&
      !!userData.dateOfBirth &&
      !!userData.address.countryCode &&
      !!userData.address.state &&
      !!userData.address.cityCode &&
      !!userData.address.addressLine
    );
  }

  private hasValidLogin(): boolean {
    // TODO: Add more validations like in the desktop site?
    return !!this.isAuthenticated;
  }
}
