export interface DataLayerEvent {
  event: string;
  [key: string]: string | number | boolean;
}

export enum DataLayerProduct {
  VirtualsScheduled = 'virtuals-scheduled',
  VirtualsInstant = 'virtuals-instant',
  SportsBookLive = 'sportsbook-live',
  SportsBookPrematch = 'sportsbook-prematch',
  SportsBookMixed = 'sportsbook-mixed',
}

export enum BetslipActions {
  AddToBetslip = 'add_to_betslip',
  BetslipOpen = 'betslip_open',
  BetslipError = 'betslip_error',
  BetSubmitted = 'bet_submitted',
  BetSuccess = 'bet_success',
  BetFailure = 'bet_failure',
  BookBetSubmitted = 'book_bet_submitted',
  BookBetSuccess = 'book_bet_success',
  BookBetFailure = 'book_bet_failure',
}

export interface BetslipActionsDetails {
  event: BetslipActions;
  product: DataLayerProduct;
  userId?: number;
  currency?: string;
  betStake?: number;
  betType?: string;
  errorMessage?: string;
  couponId?: string;
  selectionId?: number;
  location?: string;
  isFreeBet?: boolean;
  selections?: number;
}

export enum DepositActions {
  DepositMyAccountClicked = 'Deposit_MyAccount_Clicked',
  DepositHomeNavBarClicked = 'Deposit_Home_Nav_Bar_Clicked',
  DepositTutorialClicked = 'Deposit_Tutorial_Clicked',
  DepositHelpPageClicked = 'Deposit_HelpPage_Clicked',
  DepositTransactionHistoryClicked = 'Deposit_Transaction_History_Clicked',
}

export enum AdjustEvents {
  Registration = 'thowgu',
  BetSuccess = 'vp9he2',
  FirstBetSuccess = 'ry33vo',
  DepositSuccess = '4y77z3',
}

export enum CashoutActions {
  CashoutInitiated = 'cashout_initiated',
  CashoutSubmitted = 'cashout_submitted',
  CashoutSuccess = 'cashout_success',
  CashoutFailed = 'cashout_failed',
}
