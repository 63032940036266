import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { MarketModel, MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-spread-line-selector',
  templateUrl: './spread-line-selector.component.html',
  styleUrls: ['./spread-line-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpreadLineSelectorComponent implements OnInit {
  @Input() options: MarketModel[] = [];
  @Input() placeholder: string = $localize`Change`;
  @Input() isOpen$ = new BehaviorSubject<boolean>(false);
  @Input() button: boolean = false;
  @Input() selectedOption: MarketModel;
  @Input() event: MatchModel;

  @Output() readonly optionSelected = new EventEmitter<MarketModel>();

  options$ = new BehaviorSubject<MarketModel[]>([]);

  constructor(private readonly dataLayerService: DataLayerService, private readonly accountQuery: AccountQuery) {}

  ngOnInit() {
    if (this.event && this.event.groupedMarket?.length > 0) {
      this.options$.next(
        this.options.filter(option => this.event?.groupedMarket.some(groupedMarket => groupedMarket.spreadValue === option.spreadValue))
      );
    } else {
      this.options$.next(this.options);
    }
  }

  toggleDropdown() {
    this.isOpen$.next(!this.isOpen$.value);
    this.button ? this.createDataLayerEvent('total_goals_change_click') : this.createDataLayerEvent('total_goals_change_all_click');
  }

  selectOption(option: MarketModel) {
    this.isOpen$.next(false);
    this.selectedOption = option;
    this.optionSelected.emit(option);
    this.button ? this.createDataLayerEvent('total_goals_change_applied') : this.createDataLayerEvent('total_goals_change_all_applied');
  }

  private createDataLayerEvent(event: string): void {
    this.dataLayerService.createDataLayerEvent({
      event: event,
      user_id: this.accountQuery.userData?.id,
      location: window.location.pathname,
      total_goals: this.selectedOption?.name,
    });
  }
}
