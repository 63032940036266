import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { MyBetsQuery } from 'src/app/modules/my-bets/state/my-bets.query';
import { MyBetsStore } from 'src/app/modules/my-bets/state/my-bets.store';
import { fadeIn } from 'src/app/shared/animations';
import { ButtonType } from 'src/app/shared/models/button.model';
import { BetsTab } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { MyBetsService } from 'src/app/modules/my-bets/services/my-bets.service';
import { ProductType, VirtualsLeagueType } from 'src/app/shared/models/product.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ActivatedRoute } from '@angular/router';
import { SportsbookFreeBetService } from 'src/app/modules/freebets/services/sportsbook-free-bet.service';
import { FreeBetProductType } from 'src/app/modules/freebets/models/freebets.model';
import { VirtualsScheduledFreeBetService } from 'src/app/modules/freebets/services/virtuals-scheduled-free-bet.service';
import { ProductTypeService } from 'src/app/core/services/product-type.service';

@Component({
  selector: 'my-bets',
  templateUrl: './my-bets.component.html',
  styleUrls: ['./my-bets.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyBetsComponent implements OnInit, OnDestroy {
  @Input() hideBreadcrumb: boolean = false;
  @Input() hideHeader: boolean = false;
  @Input() productsToShow: ProductType[];
  @Input() virtualsLeaguesToShow: VirtualsLeagueType[];
  @Input() initialProduct: ProductType;
  @Input() initialVirtualsLeague: VirtualsLeagueType;
  @Input() skipInitialBetsTabChange: boolean = false;

  readonly buttonType: typeof ButtonType = ButtonType;
  readonly betsTab: typeof BetsTab = BetsTab;
  readonly productType: typeof ProductType = ProductType;
  readonly isFirstLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private sportsFreeBetSubscription$: Subscription;
  private virtualsScheduledFreeBetSubscription$: Subscription;
  private readonly sportsMyBetsUrl = this.appConfigService.get('sports').myBets.url;

  constructor(
    readonly accountQuery: AccountQuery,
    readonly myBetsQuery: MyBetsQuery,
    readonly myBetsStore: MyBetsStore,
    private readonly activatedRoute: ActivatedRoute,
    private readonly appConfigService: AppConfigService,
    private readonly freebetsService: SportsbookFreeBetService,
    private readonly virtualsScheduledFreeBetService: VirtualsScheduledFreeBetService,
    private readonly location: Location,
    private readonly myBetsService: MyBetsService,
    private readonly productTypeService: ProductTypeService
  ) {}

  get productList$(): Observable<ProductType[]> {
    return this.myBetsQuery.selectedBetsTab$.pipe(
      map(selectedBetsTab => {
        // use productsToShow if it's not empty, else use the full list of enum entries
        const productList = this.productsToShow?.length ? this.productsToShow : Object.values(ProductType);
        const configCheck = (productConfigKey: 'virtuals' | 'jackpotBets') =>
          // Hide Virtuals or JackpotBets products if:
          // - in Booked Bets if it is disabled
          // - globally disabled
          (selectedBetsTab !== BetsTab.Booked && this.appConfigService.get(productConfigKey).enabled) ||
          (selectedBetsTab === BetsTab.Booked &&
            this.appConfigService.get(productConfigKey).enabled &&
            this.appConfigService.get(productConfigKey).bookedBets.enabled);

        return productList.filter(product => {
          switch (product) {
            case ProductType.Virtuals:
              return configCheck('virtuals');
            case ProductType.JackpotBets:
              return this.accountQuery.isJackpotBetsEnabled && configCheck('jackpotBets');
            default:
              return true;
          }
        });
      }),
      takeUntil(this.destroy$)
    );
  }

  get virtualsLeaguesList$(): Observable<VirtualsLeagueType[]> {
    return this.myBetsQuery.virtualsLeagueTabs$.pipe(
      map(virtualsLeagueTabs =>
        // use virtualsLeaguesToShow to filter the league list if it's not empty
        this.virtualsLeaguesToShow?.length
          ? virtualsLeagueTabs.filter(league => this.virtualsLeaguesToShow.includes(league))
          : virtualsLeagueTabs
      ),
      takeUntil(this.destroy$)
    );
  }

  get isVirtualsInstantLeague$(): Observable<boolean> {
    return combineLatest([this.myBetsQuery.selectedProductTab$, this.myBetsQuery.selectedVirtualsLeagueTab$]).pipe(
      map(([selectedProductTab, selectedVirtualsLeagueTab]) => {
        return selectedProductTab === ProductType.Virtuals && selectedVirtualsLeagueTab === VirtualsLeagueType.Instant;
      }),
      takeUntil(this.destroy$)
    );
  }

  get virtualsLeagueText$(): Observable<string> {
    return combineLatest([
      this.myBetsQuery.selectedBetsTab$,
      this.myBetsQuery.selectedVirtualsLeagueTab$,
      this.myBetsQuery.myBetsContent$,
    ]).pipe(
      map(([selectedBetsTab, selectedVirtualsLeagueTab, myBetsContent]) => {
        switch (selectedVirtualsLeagueTab) {
          case VirtualsLeagueType.Scheduled:
            return selectedBetsTab === BetsTab.Open
              ? myBetsContent?.virtualsScheduledLeagueOpenBetsText
              : myBetsContent?.virtualsScheduledLeagueSettledBetsText;
          case VirtualsLeagueType.Instant:
            return selectedBetsTab === BetsTab.Open
              ? myBetsContent?.virtualsInstantLeagueOpenBetsText
              : myBetsContent?.virtualsInstantLeagueSettledBetsText;
          default:
            return;
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  ngOnInit(): void {
    if (!this.myBetsQuery.myBetsContent) {
      this.myBetsService.initialize();
    }

    this.myBetsStore.setDefaultVirtualsLeagueTab();

    combineLatest([this.activatedRoute.data, this.activatedRoute.params, this.activatedRoute.queryParams])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([routeData, routeParams, routeQueryParams]) => {
        // priority of the initial selected tabs is @Input() > routeData > myBetsQuery
        let productTab: ProductType = this.initialProduct || routeData?.product || this.myBetsQuery.selectedProductTab;
        const virtualsLeagueTab: VirtualsLeagueType =
          this.initialVirtualsLeague || routeData?.virtualsLeague || this.myBetsQuery.selectedVirtualsLeagueTab;
        let betsTab = BetsTab.Open;

        if (productTab === ProductType.Virtuals) {
          if (this.appConfigService.get('virtuals')?.enabled) {
            if (virtualsLeagueTab === VirtualsLeagueType.Instant) {
              betsTab = BetsTab.Settled;
            }
          } else {
            productTab = ProductType.SportsBook; // default to Sports product if virtuals is not enabled
          }
        } else if (productTab === ProductType.JackpotBets) {
          if (!this.accountQuery.isJackpotBetsEnabled || !this.appConfigService.get('jackpotBets')?.enabled) {
            productTab = ProductType.SportsBook; // default to Sports product if JPB is not enabled
          }
        }

        // if betsTab is specified in the route and has a valid value, give it priority over the above logic
        if (routeParams?.betsTab) {
          switch (routeParams?.betsTab.toLowerCase()) {
            case BetsTab.Open:
              betsTab = BetsTab.Open;
              break;
            case BetsTab.Settled:
              betsTab = BetsTab.Settled;
              break;
            case BetsTab.Booked:
              betsTab = BetsTab.Booked;
              break;
            default:
              break;
          }
        }

        // If the URL contains 'coupons=<value>' as part of the querystring parameters,
        // the view will automatically switch to the Settled tab and the matched coupons
        // will automatically be expanded.
        // <value> should contain one or more comma separated coupon codes
        const couponCodes = routeQueryParams?.['coupons']?.split(',').filter(c => c);
        if (couponCodes) {
          this.myBetsService.setAutoExpandCouponCodes(couponCodes);
          betsTab = BetsTab.Settled;
        }

        if (!this.skipInitialBetsTabChange) {
          this.changeBetsTab(betsTab);
        }
        this.changeProductTab(productTab);
        this.changeVirtualsTab(virtualsLeagueTab);

        this.subscribeToDataChanges();

        this.myBetsQuery.isBookedBetEnabled$
          .pipe(
            tap(isBookedBetEnabled => {
              if (this.myBetsQuery.selectedBetsTab === BetsTab.Booked && !isBookedBetEnabled) {
                // Default to Open tab if Booked tab is currently selected whilst switching to product which does not support it
                this.changeBetsTab(BetsTab.Open);
              }
            }),
            takeUntil(this.destroy$)
          )
          .subscribe();
      });
  }

  ngOnDestroy(): void {
    this.myBetsService.clearCashouts();
    this.myBetsService.clearSettledBets();
    this.myBetsService.clearExpandedBets();
    this.myBetsStore.clearAutoExpandCouponCodes();

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  goBack(): void {
    this.location.back();
  }

  changeBetsTab(tab: BetsTab): void {
    if (this.myBetsQuery.isBookedBetEnabled || tab !== BetsTab.Booked) {
      this.myBetsStore.changeBetsTab(tab);
    }
  }

  changeProductTab(tab: ProductType): void {
    if (tab === ProductType.SportsBook && this.sportsMyBetsUrl) {
      window.location.href = this.sportsMyBetsUrl;
    } else {
      this.myBetsStore.changeProductTab(tab);
      this.productTypeService.updateActiveProduct(tab);
      this.changeFreeBetActiveProduct(tab);
    }
  }

  changeVirtualsTab(league: VirtualsLeagueType): void {
    if (this.myBetsQuery.virtualsLeagueTabs.includes(league)) {
      this.myBetsStore.changeVirtualsLeagueTab(league);
    } else {
      this.myBetsStore.setDefaultVirtualsLeagueTab();
    }
  }

  private subscribeToDataChanges(): void {
    combineLatest([this.myBetsQuery.selectedProductTab$, this.myBetsQuery.selectedBetsTab$, this.myBetsQuery.selectedVirtualsLeagueTab$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([selectedProductTab, selectedBetsTab, selectedVirtualsLeagueTab]) => {
        if (selectedBetsTab === BetsTab.Open) {
          this.myBetsService.getOpenBets();
        } else if (selectedBetsTab === BetsTab.Settled) {
          this.myBetsService.getSettledBets();
        } else if (selectedBetsTab === BetsTab.Booked) {
          // Clear other tabs' data
          this.myBetsService.clearOpenBets();
          this.myBetsService.clearSettledBets();
        }
      });
  }

  private changeFreeBetActiveProduct(tab: ProductType): void {
    if (tab === ProductType.SportsBook) {
      if (this.virtualsScheduledFreeBetSubscription$) {
        this.virtualsScheduledFreeBetSubscription$.unsubscribe();
      }
      // Sprots Freebets Initialisation
      this.freebetsService.setActiveFreeBetProduct(FreeBetProductType.SportsBook);
      this.sportsFreeBetSubscription$ = forkJoin([
        this.freebetsService.initialiseFreebetsConfig(),
        this.freebetsService.initialiseFreebetsMyBetsContent(),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    } else if (tab === ProductType.Virtuals) {
      // Scheduled Freebets Initialisation
      if (this.sportsFreeBetSubscription$) {
        this.sportsFreeBetSubscription$.unsubscribe();
      }

      this.virtualsScheduledFreeBetService.setActiveFreeBetProduct(FreeBetProductType.VirtualsScheduled);
      this.virtualsScheduledFreeBetSubscription$ = forkJoin([
        this.virtualsScheduledFreeBetService.initialiseFreebetsConfig(),
        this.virtualsScheduledFreeBetService.initialiseFreebetsMyBetsContent(),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }
  }
}
