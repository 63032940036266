import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatchService } from 'src/app/modules/sport/services/match.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { expandCollapse } from 'src/app/shared/animations';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { MarketModel, MatchModel } from 'src/app/shared/models/sport.model';
import { StatisticsType } from 'src/app/shared/models/statistics.model';

@Component({
  selector: 'app-single-line-event',
  templateUrl: './single-line-event.component.html',
  styleUrls: ['./single-line-event.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleLineEventComponent {
  @Input() event: MatchModel;
  @Input() sportId: number;
  @Input() showDate = true;
  @Input() showDetails: boolean = true;
  @Input() last?: boolean;
  @Input() selectedMarket: MarketModel = undefined;
  @Input() groupedMarkets: MarketModel[] = [];
  @Input() selectedOption: MarketModel = undefined;
  @Input() eventLocation: string = undefined; // Used for data layer events
  @Input() itemIndex: number;
  @Input() isLiveListWidget: boolean = false;

  @Output() readonly optionSelected = new EventEmitter<MarketModel>();

  statisticsType = StatisticsType.PreMatchList;

  constructor(readonly statisticsQuery: StatisticsQuery, private readonly matchService: MatchService, private readonly router: Router) {}

  getOdds(): OddModel[] {
    // Setting the odds based on the selected market for the specific event.
    if (this.groupedMarkets?.length > 0) {
      return this.selectedOption
        ? this.selectedOption.selections
          ? this.selectedOption.selections.map(m =>
              this.event.groupedMarket.find(
                odd =>
                  odd?.marketTypeId === this.selectedOption.typeId &&
                  odd?.spreadValue === this.selectedOption.spreadValue &&
                  odd?.selectionName === m.name
              )
            )
          : this.event.groupedMarket.filter(
              odd => odd?.marketTypeId === this.selectedOption.typeId && odd.spreadValue === this.selectedOption.spreadValue
            )
        : this.event.odds;
    }
    return this.selectedMarket
      ? this.selectedMarket.selections
        ? this.selectedMarket.selections.map(m =>
            this.event.odds.find(
              odd =>
                odd?.marketTypeId === this.selectedMarket.typeId &&
                odd?.spreadValue === this.selectedMarket.spreadValue &&
                odd?.selectionName === m.name
            )
          )
        : this.event.odds.filter(
            odd => odd?.marketTypeId === this.selectedMarket.typeId && odd.spreadValue === this.selectedMarket.spreadValue
          )
      : this.event.odds;
  }

  navigateToMatch(matchId: number, name: string, isLive: boolean): void {
    if (!matchId) return;

    if (isLive) {
      // TODO with task BCON-2030: kept this below line since we will soon replace it once we go live with the React pages again
      // window.location.href = `${window.location.origin}/sports/live/${matchId}`;
      this.router.navigateByUrl(`/live/${matchId}`);
    } else {
      this.matchService.navigateToMatch(matchId, name);
    }
  }

  handleOptionSelected(event: MarketModel) {
    this.selectedOption = event;
  }
}
