import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { ChangeDetectionStrategy, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ButtonType } from 'src/app/shared/models/button.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { AdjustContainerOnHorizontalScrollDirective } from 'src/app/shared/directives/adjust-container-on-horizontal-scroll.directive';
import { BestSellerModel } from 'src/app/shared/models/coupon.model';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { BetslipActions } from 'src/app/shared/models/datalayer.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'coupon-empty',
  templateUrl: './coupon-empty.component.html',
  styleUrls: ['./coupon-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponEmptyComponent implements OnInit {
  @Input() customButtonStyle: any = {};

  @ViewChildren(AdjustContainerOnHorizontalScrollDirective) viewChildren: QueryList<AdjustContainerOnHorizontalScrollDirective>;

  buttonType: typeof ButtonType = ButtonType;
  enableSlideUps = this.appConfigService.get('enableSlideUps');
  titleLabel = {
    1: $localize`Single`,
    2: $localize`Double`,
    3: $localize`Treble`,
    default: $localize`Fold Acca`,
  };

  constructor(
    readonly couponQuery: CouponQuery,
    private readonly router: Router,
    private readonly location: Location,
    private readonly appConfigService: AppConfigService,
    private readonly applicationService: ApplicationService,
    private readonly couponService: CouponService,
    private readonly accountQuery: AccountQuery,
    private readonly dataLayerService: DataLayerService,
    private readonly clipboard: Clipboard,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.couponService.fetchEmptyBetslipData();
  }

  couponTrackBy(index: number, coupon: BestSellerModel): number {
    return coupon.idBestSeller;
  }

  goBack(): void {
    this.location.back();
  }

  continueBetting(): void {
    if (this.enableSlideUps) {
      this.applicationService.closeAnySlideUps();
    }

    this.router.navigate(['/']);
  }

  addToBetslip(coupon: BestSellerModel) {
    this.createDataLayerEvent();
    this.couponService.updateCouponSetting('bookedCouponCode', coupon.bookedCouponCode);
    this.couponService.createCouponFromSelectionIds(coupon.odds.map(odd => odd.IDSelection)).subscribe(() => {
      this.router.navigateByUrl(`/coupon`, { skipLocationChange: true });
    });
  }

  navigateTo(link: string) {
    this.router.navigateByUrl(link ? link : '/');
  }

  handleCopyBookingCode = (code: string) => {
    this.clipboard.copy(code);
    this.notificationService.showInfoMessage($localize`Booking code copied to clip board`, 2000, false);
  };

  private createDataLayerEvent(): void {
    this.dataLayerService.createDataLayerEvent({
      event: BetslipActions.AddToBetslip,
      user_id: this.accountQuery.userData?.id,
      location: 'empty_betslip',
    });
  }
}
